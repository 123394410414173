import React, { Suspense } from "react"
import { createRoot } from "react-dom/client"
import * as serviceWorker from "./serviceWorker"
import "ui/dist/index.css"

const init = async () => {
    // load env into windows before rendering the app
    const container = document.getElementById("root")
    const root = createRoot(container!)

    try {
        const App = React.lazy(() => import("./pages/App"))

        const isMockData = localStorage.getItem("mockSwitchValue")

        if (isMockData === "true") {
            await import("./services/Request/mpayApi/mockAdapter")
        }

        root.render(
            <React.StrictMode>
                <Suspense fallback={null}>
                    <App />
                </Suspense>
            </React.StrictMode>,
        )
    } catch (error) {
        console.error(error)
        root.render(
            <React.StrictMode>
                <h1>
                    Unable to load public/env.json, check the console for
                    details.
                </h1>
            </React.StrictMode>,
        )
    }
}

init()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
